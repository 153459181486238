//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      list: [],
      parameter: "",
      styleList: [],
      fold: false
    };
  },

  props: {
    /**
     * list 数据
     */
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    iconListStatu: {
      type: Boolean,
      default: false
    },
    /**
     * 默认Radio
     */
    disabledRadio: {
      type: Boolean,
      default: false
    },
    /**
     * title 宽度
     */
    spanWidth: {
      type: [String, Number],
      default: "100"
    },
    /**
     * 图标
     */
    iconList: {
      type: Array,
      default: () => {
        return [
          { url: require("@/assets/company/distribution1.png") },
          { url: require("@/assets/company/distribution2.png") },
          { url: require("@/assets/company/distribution3.png") }
        ];
      }
    },
    /**
     * 数据显示
     */
    cardText: {
      type: Array,
      default: val => {
        return [
          {
            title: "发票抬头：",
            parameter: "对应参数名"
          },
          {
            title: "纳税人识别号：",
            parameter: "对应参数名"
          }
        ];
      }
    },
    /**
     * 编辑
     */
    editCard: {
      type: Function,
      default: () => {
        console.log("编辑");
      }
    },
    /**
     * 删除
     */
    deleteCard: {
      type: Function,
      default: () => {
        console.log("删除");
      }
    }
  },
  watch: {
    dataList() {
      this.getList();
      this.fold = false;
      this.setStyleMargin(this.dataList);
    }
  },
  created() {
    this.getList();
  },
  methods: {
    setStyleMargin(list) {
      let even = true,
        arr = [1],
        stp = 0;
      list.map((item, index) => {
        if (index > 1) {
          stp++;
          if (stp > 2) {
            stp = 0;
            arr.push(index);
          }
        }
      });
      this.styleList = arr;
    },
    getList() {
      if (this.validation())
        return this.$message({
          type: "warning",
          message: `传入参数未找到：${this.parameter}`
        });
      this.list = this.dataList.slice(0, 6);
    },
    showMore() {
      this.fold = !this.fold;
      this.fold
        ? (this.list = this.dataList)
        : (this.list = this.dataList.slice(0, 6));
    },
    /**
     * 验证参数
     */
    validation() {
      if (this.dataList.length === 0) return;
      let statu = this.cardText.some(name => {
        return this.dataList.some(item => {
          if (Object.keys(item).indexOf(name.parameter) === -1) {
            this.parameter = name.parameter;
            return true;
          }
        });
      });
      return statu;
    },
    /**
     * 选中状态 设置为唯一
     */
    radioStatu(val) {
      this.$emit("selectDefault", val);
    }
  }
};
