import CardBox from '@/component/cardBox'
import DialogBox from '@/component/dialog'
import addShippingAddress from '@/lib/data-service/haolv-default/consumer_web_shippingAddress_addShippingAddress'
import delShippingAddress from '@/lib/data-service/haolv-default/consumer_web_shippingAddress_delShippingAddress'
import getCompanyInvoiceList from '@/lib/data-service/haolv-default/consumer_web_shippingAddress_getCompanyInvoiceList'
import updateShippingAddress from '@/lib/data-service/haolv-default/consumer_web_shippingAddress_updateShippingAddress'
import { verifyPhone, verifylandlinePhone, verifySpace } from '@/lib/utils/verify'

export default {
    name: "distribution",
    data() {
        return {
            cardText: [
                {
                    title: "收件人：",
                    parameter: "receiver"
                },
                {
                    title: "手机号码：",
                    parameter: "phone"
                },
                {
                    title: "座机电话：",
                    parameter: "landlinePhone"
                },
                {
                    title: "地址：",
                    parameter: "address"
                },
                {
                    title: "配送方式：",
                    parameter: "distributionWayText"
                },
            ],
            editShow: false,
            formData: {
                distributionWay: 1,
                label: '公司'
            },
            distributionList: [],
            rules: {
                receiver: [
                    { required: true, message: '请输入收件人', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: verifyPhone }
                ],
                landlinePhone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                    { validator: verifylandlinePhone }
                ],
                address: [
                    { required: true, message: '请输入地址', trigger: 'blur' },
                    { validator: verifySpace }
                ],
                distributionWay: [
                    { required: true, message: '请选择配送方式', trigger: 'change' }
                ],
                label: [
                    { required: true, message: '请选择标签', trigger: 'change' }
                ],
            }
        };
    },
    component: {

    },
    created() {
        this.getDistributionList()
    },
    components: { CardBox, DialogBox },
    methods: {
        editCard(val) {
            this.formData = Object.assign({}, val)
            this.editShow = true
            this.$refs['formData'].resetFields();
            this.dialogType = 'edit'
        },
        deleteCard(val) {
            this.$confirm('确定要删除操作吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.delDistributionList({ id: val.id })
            }).catch(() => {

            });
        },
        //默认地址
        selectDefault(val) {
            updateShippingAddress(val).then(res => {
                this.getDistributionList()
            })
        },
        addCard() {
            this.editShow = true
            this.$refs['formData'].resetFields();
            this.formData = {}
            this.dialogType = 'add'
        },
        delDistributionList(id) {
            delShippingAddress(id).then(res => {
                this.editShow = false
                this.getDistributionList()
                this.$message({ type: "success", message: "删除成功！" })
            })
        },
        addDistributionList() {
            let data = this.formData
            addShippingAddress(data).then(res => {
                this.editShow = false
                this.getDistributionList()
                this.$message({ type: "success", message: "添加成功！" })
            })
        },
        getDistributionList() {
            let data = {
                searchCriteria: this.formData.searchCriteria || ''
            }
            getCompanyInvoiceList(data).then(res => {
                console.log(res)
                res.datas.map(item => item.distributionWayText = item.distributionWay === 1 ? '快递' : '平邮')
                this.distributionList = res.datas
            })
        },
        editDistributionList() {
            let data = this.formData
            updateShippingAddress(data).then(res => {
                this.editShow = false
                this.getDistributionList()
                this.$message({ type: "success", message: "修改成功！" })
            })
        },
        cancel(val) {
            this.formData = {}
            this.editShow = val
        },
        setDistributionList(self) {
            let _this = self.$parent
            _this.$refs['formData'].validate(valid => {
                console.log(valid)
                if (valid) {
                    try {
                        if (_this.dialogType === 'add') {
                            _this.addDistributionList()
                        } else {
                            _this.editDistributionList()
                        }
                    } catch{ }
                } else {
                    return false
                }
            })
        },
    }
};